import { Modal } from '~/components';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import {
  Container,
  Content,
  Table,
  Td,
  TdDate,
  TdPercentage,
  Th,
  ThDate,
  ThPersentage,
  Title,
  TitleModal,
  TrFooter,
} from '~/pages/Reports/ModalDetailsCalculation/styles';
import { ListFrequencyReportResponseDTO } from '~/services/dtos/frequencyServiceDTO';

interface IProps {
  list: ListFrequencyReportResponseDTO[];
  totalPercentage: number;
  totalMembers: number;
  totalPresence: number;
  onClick: () => void;
}

const ModalDetailsCalculation: React.FC<IProps> = ({
  onClick,
  list,
  totalPercentage,
  totalMembers,
  totalPresence,
}: IProps) => {
  const parsedPencentage = Number(totalPercentage?.toFixed());
  const parsedPresence = Number(totalPresence?.toFixed());
  const parsedMembers = Number(totalMembers?.toFixed());
  return (
    <Modal>
      <Container>
        <TitleModal>
          <button type="button" onClick={onClick}>
            <IoMdCloseCircleOutline />
          </button>
        </TitleModal>
        <Content>
          <Title>Detalhes do cálculo</Title>
          <Table>
            <thead>
              <tr>
                <ThDate>Data</ThDate>
                <Th>Presentes</Th>
                <Th>Total</Th>
                <ThPersentage>Percentual</ThPersentage>
              </tr>
            </thead>
            <tbody style={{ marginBottom: 10 }}>
              {list.map(item => (
                <tr>
                  <TdDate>
                    {new Date(item.date).toLocaleDateString('pt-BR', {
                      day: '2-digit',
                      month: 'long',
                    })}
                  </TdDate>
                  <Td>{item.totalPresence}</Td>
                  <Td>{item.membersNumber}</Td>
                  <TdPercentage>{item.percentage} %</TdPercentage>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <TrFooter>
                <TdDate>Média</TdDate>
                <Td>{parsedPresence || '0'}</Td>
                <Td>{parsedMembers || '0'}</Td>
                <TdPercentage>{parsedPencentage || '0'}%</TdPercentage>
              </TrFooter>
            </tfoot>
          </Table>
        </Content>
      </Container>
    </Modal>
  );
};

export default ModalDetailsCalculation;
