import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow: auto;
`;

export const Content = styled.div`
  margin-top: 3%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1vh;

  svg {
    margin-bottom: 10px;
  }
  button {
    margin-top: 20px;
  }
  p {
    font-size: 14px;
    width: 100%;
    word-wrap: break-word;
    text-align: center;
  }
`;

export const TitleModal = styled.div`
  b {
    font-size: 20px;
  }
  svg {
    color: #488cfa;
  }
  display: flex;
  justify-content: right;
  margin-bottom: -20px;
`;

export const Title = styled.b`
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 20px;
`;

export const Table = styled.table`
  width: 100%;

  tr {
    text-align: justify;
  }

  tbory {
    padding: 20px 0px 20px 0px;
  }
`;

export const Th = styled.th`
  text-align: center;
`;

export const ThPersentage = styled.th`
  text-align: end;
`;

export const ThDate = styled.th`
  text-align: justify;
`;

export const TdDate = styled.td`
  text-align: justify;
`;

export const Td = styled.td`
  text-align: center;
`;

export const TdPercentage = styled.td`
  text-align: end;
`;

export const TrFooter = styled.tr`
  text-align: justify;
  font-weight: 700;
  background: rgba(0, 0, 0, 0.1);
`;
